@import "/src/core-ui/screens";

.shareholders-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: #f6f9fb;
    padding: 60px 16px;
    text-align: center;

    & > h1 {
        margin-bottom: 0.9735rem;
    }
}

.shareholders-section-images {
    display: flex;
    justify-content: center;
    gap: 0.9375rem;
    margin-bottom: 0.9735rem;
    width: 100%;

    transition: opacity 2s ease-in;
    opacity: 0;
}

.shareholders-section-images[shareholders-in-view="true"] {
    opacity: 1;

}

.shareholders-section-img-box {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--ies-white);

    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    width: 100px;
    height: 60px;

    & > img {
        width: 75%;
        height: auto;
    }
}

@media (min-width: map-get($screen-sizes, "md")) {
    .shareholders-section {
        padding: 140px 16px;
        & > h1 {
            margin-bottom: 1.875rem;
        }
    }
    .shareholders-section-images {
        gap: 1.875rem;
        margin-bottom: 1.875rem;
    }
    .shareholders-section-img-box {
        width: 150px;
        height: 90px;
    }
}

@media (min-width: map-get($screen-sizes, "md")) {
    .shareholders-section-img-box {
        width: 200px;
        height: 140px;
    }
}
