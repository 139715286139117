@import "/src/core-ui/screens";

.team-page {
    position: relative;

    width: 100vw;
    min-height: max(calc(100vh - var(--nav-bar-height)), 320px);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    & * {
        color: var(--ies-white)
    }
}

.team-page-bg-regular {
    position: absolute;

    background: 
        linear-gradient(195.11deg, rgba(11, 48, 74, 0) 51.11%, rgba(11, 48, 74, 0.9) 81.08%), 
        linear-gradient(180deg, #FFFFFF 3.74%, rgba(255, 255, 255, 0) 16.95%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    height: 100%;
}

.team-page-bg-small {
    position: relative;
    width: 100%;
    height: 300px;
    flex-grow: 1;
    
    background: 
        linear-gradient(182.23deg, rgba(11, 48, 74, 0) 67.05%, #0B304A 95.74%), 
        linear-gradient(180deg, #FFFFFF 3.74%, rgba(255, 255, 255, 0) 16.95%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.team-page-content {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: var(--ies-secondary);
    z-index: 1;
}

@media (min-width: map-get($screen-sizes, "md")) {
    .team-page-content {
        background-color: unset;
    }
}
