@import "/src/core-ui/screens";

.welcome-section-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: max(calc(80vh - var(--nav-bar-height)), 520px);
}

.welcome-section-content {
    padding-top: 8vh;
    
    & > h1 {
        font-size: 2.125rem;
    }

    & > h2 {
        font-size: 1.25rem;
    }
}

.welcome-section-content {
    padding-top: 8vh;
    
    & > h1 {
        font-size: 1.9375rem;
    }

    & > h2 {
        font-size: 1rem;
    }
}

@media (min-width: 380px) {
    .welcome-section-content {        
        & > h1 {
            font-size: 2.125rem;
        }
    
        & > h2 {
            font-size: 1.25rem;
        }
    }
}


@media (min-width: map-get($screen-sizes, "md")) {
    .welcome-section-content {
        padding-top: 10vh;

        & > h1 {
            font-size: 2.375rem;
        }
    
        & > h2 {
            font-size: 1.5rem;
        }
    }
}

@media (min-width: map-get($screen-sizes, "xl")) {
    .welcome-section-content {
        padding-top: 12vh;

        & > h1 {
            font-size: 2.525rem;
        }
    
        & > h2 {
            font-size: 1.75rem;
        }
    }   
}