@import "/src/core-ui/screens";


.contact-us-page {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100vw;
    min-height: calc(100vh - var(--nav-bar-height));

    & * {
        color: var(--ies-white)
    }
}

.contact-us-content {
    text-align: center;

    padding: 3.25rem 3.75rem;

    background-color: var(--ies-secondary);
    opacity: 0.8;
    border-radius: 5px;
}

.contact-us-icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
}

@media (min-width: calc(map-get($screen-sizes, "sm") + 56px)) {
    .contact-us-icons {
        flex-direction: row;
        gap: 1.5rem;
    }
}

@media (min-width: map-get($screen-sizes, "md")) {
    .contact-us-content {
        padding: 3.25rem 5.625rem;
    }
}

