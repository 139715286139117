@import "/src/core-ui/screens";

.team-section--sm {
 width: 100%;
 * img {
    object-fit: contain;
    width: 100%;
    height: 100%;
 }
}

.team-section-img-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: -1%;
    background: linear-gradient(0, #0B304A 1.26%, rgba(11, 48, 74, 0) 32.89%);
}

.home-team-section-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    text-align: center;
    align-items: center;

    background-color: var(--ies-secondary);
}

.home-team-section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0rem;

    & > h1 {
        font-size: 1.425rem;
        color: rgba(255, 255, 255, 0.05);
        text-transform: uppercase;
        //border: 1px solid #FFFFFF;
        -webkit-text-stroke: 0.5px var(--ies-white);
    }
}

.home-team-section--reg {
    display: grid;
    grid-template-columns: 2fr 1fr;

    & > img {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: map-get($screen-sizes, "sm")) {
    .home-team-section-content {
        & > h1 {
            font-size: 1.75rem;
        }
    }
}

@media (min-width: map-get($screen-sizes, "md")) {
    .home-team-section-content-container {
        text-align: initial;
        align-items: initial;
    }

    .home-team-section-content {
        display: block;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 12%;

        & > h1 {
            color: var(--ies-secondary);
            font-size: calc(1.5rem + 2vw);
            -webkit-text-stroke: 1px var(--ies-white);
        }
    }
}

@media (min-width: map-get($screen-sizes, "xl")) {
    .home-team-section--reg {
        grid-template-columns: 6fr 4fr;
    }

    .home-team-section-content {
        & > h1 {
            font-size: 4.75rem;
        }
    }
}