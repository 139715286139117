@import "/src/core-ui/screens";

.deco-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    text-transform: uppercase;
    margin-bottom: 1.25rem;

    & > h1 {
        margin-bottom: 0px;
        font-size: 1.125rem;
    }
}

.deco-header-ellipse {
    max-width: 1.5rem;
}


@media (min-width: map-get($screen-sizes, "md")) {
    .deco-header {
        gap: 1rem;     
        margin-bottom: 2rem;

        & > h1 {
            font-size: 1.5rem;
        }
    }

    .deco-header-ellipse {
        max-width: 1.75rem;
    }
}

@media (min-width: map-get($screen-sizes, "xl")) {
    .deco-header {
        margin-bottom: 3.125rem;

        & > h1 {
            font-size: 2.625rem;
        }
    }

    .deco-header-ellipse {
        max-width: 3.5rem;
    }
}
