@import "/src/core-ui/screens";

$content-padding-top: 0.725rem;
$content-padding-left: 1.25rem;
$transition-time: 350ms;
$img-card-border-radius: 0.325rem;

.img-card {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    filter: drop-shadow(4px 4px 7px rgba(0, 0, 0, 0.08));
    border-radius: $img-card-border-radius;
    z-index: 10;
    overflow: hidden;
}

.img-card:hover .img-card-content {
    transform: translateY(100%);
}

.img-card:hover .img-card-mask {
    background-color: $secondary;
}

.img-card:hover .img-card-mask-title {
    opacity: 1;
}

@media (min-width: 768px) {
    .img-card {
        & h1 {
            font-size: 1.5rem;
        }
    }
}

.img-card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.img-card-content {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% - calc((var(--#{$prefix}body-font-size-base) + $content-padding-top + $h1-font-size)));
    bottom: 0;

    padding-left: $content-padding-left;
    padding-top: $content-padding-top;

    overflow: hidden;

    background-color: $secondary;

    & > * {
        color: $white;
        text-overflow: ellipsis;
    }

    & > h2 {
        margin-bottom: 0px;
    }

    transition: transform ease-in-out $transition-time;

}

.img-card-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    &[data-gradient="true"] {
        background: linear-gradient(180deg, rgba(11, 48, 74, 0) 0%, $secondary 51.69%, rgba(11, 48, 74, 0) 100%);
    }

    mix-blend-mode: normal;
    opacity: 0.5;

    transition: background-color ease-in-out $transition-time;
}

.img-card-mask-title {
    position: absolute;
    text-transform: uppercase;
    z-index: 1;
    & > h2 {
        color: #ECF0F2;
        font-size: 1rem;
        text-align: center;
        margin-bottom: 0px;
    }

    &[data-displayonlyonhover=true] {
        opacity: 0;
    }

    transition: opacity linear $transition-time;
}

@media (min-width: map-get($screen-sizes, "md")) {
    .img-card-mask-title {
        & > h2 {
            font-size: 1.5rem;
        }
    }
}

@media (min-width: map-get($screen-sizes, "xl")) {
    .img-card-mask-title {
        & > h2 {
            font-size: 1.625rem;
        }
    }
}
