@import "./screens";

// prefix
$prefix: ies-;

// colors
$primary: #3D6480;
$secondary: #0B304A;
$light: #ECF0F2;

// body
$body-color: $secondary;

// font
$font-family-sans-serif: "Source Sans Pro";

$font-size-base: 1.125rem;
$font-size-sm: 0.9375rem;

// headings
$headings-font-family: "Oswald";
$headings-font-weight: 600;
$headings-color: $secondary;

// spacers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
);

// link
$link-hover-color: $secondary;

// borders
$border-radius:               0.1875rem;
$border-radius-sm:            0px;
$border-radius-lg:            0px;
$border-radius-xl:            0px;
$border-radius-2xl:           0px;
$border-radius-pill:          0px;

// btn
$btn-color: #fff;
$btn-padding-x:               1.25rem;
$btn-padding-y:               0.675rem;

$btn-line-height: 1;

// containers
$container-max-widths: (
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
) !default;

$container-padding-x: 3rem;

// p
$paragraph-margin-bottom: 0.125rem;

// link
$link-decoration: none;

@import "./bsAdjactedVariables";