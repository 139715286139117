.props-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;

    padding-top: 3rem;
    padding-bottom: 2rem;

    min-height: calc(100vh - var(--nav-bar-height));
}

.props-page-gallery {
    width: 100%;
    gap: 24px;

    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 320px);

}

@media (min-width: 380px) {
    .props-page-gallery {
        max-width: 420px;
    }
}

@media (min-width: 768px) {
    .props-page-gallery {
        max-width: none;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }

    .props-page-gallery-item--1 {
        grid-column: 1 / 2;
    }
    
    .props-page-gallery-item--2 {
        grid-column: 2 / 3;
    }
}