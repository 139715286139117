@import "/src/core-ui/screens";

.fotter {
    background-color: var(--ies-light);
    font-size: 0.825rem;
}

@media (min-width: map-get($screen-sizes, "sm")) {
    .fotter {
        font-size: 1.125rem;
    }
}

.fotter-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    padding: 18px 48px;

    & * {
        color: #3D6480;
    }

    & a {
        color: inherit;
    }

    & img path {
        stroke: var(--ies-primary);
    }
}

@media (min-width: map-get($screen-sizes, "xl")) {
    .fotter-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    
        padding: 18px 96px;
    }
}

.fotter-content--sm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    padding: 1.125rem 1rem;
}
