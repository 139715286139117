@import "/src/core-ui/screens";

.clients-section {
    background-color: #f6f9fb;
    padding: 96px 0;
    text-align: center;

    & .swiper-wrapper {
        transition-timing-function : linear;
        align-items: center;
    }
    & .swiper-slide {
        background-color: #f6f9fb;
        & > img {
            mix-blend-mode: darken;
        }
    }
}

.swiper-grid-column > .swiper-wrapper{ flex-direction: unset !important; }

.clients-section-swiper--sm {
    max-width: 450px;
}

@media (min-width: map-get($screen-sizes, "md")) {
    .clients-section-swiper-slide {
        max-width: 100px;
    }
}

@media (min-width: map-get($screen-sizes, "xl")) {
    .clients-section-swiper-slide {
        max-width: 150px;
    }
}

