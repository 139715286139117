@import "/src/core-ui/screens";

.prop-page {
    position: relative;
    display: flex;
    flex-direction: column;

    min-height: calc(100vh - var(--nav-bar-height));
}

.prop-page-content-container {
    display: flex;
    flex-direction: column;

    justify-content: center;

    background-color: var(--ies-secondary);
    flex-grow: 1;
    & * {
        color: var(--ies-white)
    } 
}

.prop-page-back-arrow {
    position: absolute;
    left: 1rem;
    top: 1rem;
}

.prop-page-desc-container {
    flex-grow: 1;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.prop-page-desc {
    display: flex;
    flex-direction: column;

    height: 100%;
}

@media (min-width: map-get($screen-sizes, "md")) {
    .prop-page {
        display: grid;
        grid-template-columns: 4fr 6fr;

        justify-content: unset;
        align-items: unset;
        padding: 0;
    
    }

    .prop-page-content-container {
        position: relative;
        background-color: unset;
        justify-content: initial;
        flex-grow: initial;
        background: rgba(#0B304A, 0.8);
        
        & > img {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
            height: 100%;
            width: auto;
        }
    }

    .prop-page-back-arrow {
        position: relative;
        left: initial;
        top: initial;

        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1rem;
    }

    .prop-page-desc-container {
        margin-bottom: 0;
        margin-top: 0;

        & * {
            color: var(--ies-white);
        }
    }

    .prop-page-desc {
        justify-content: flex-start;
        padding: 2rem;

        & > h1 {
            font-size: 1.5rem;
        }

        & > p {
            font-size: 0.925rem;
        }

    }
}

@media (min-width: map-get($screen-sizes, "xl"))  {
    .prop-page-desc {
        justify-content: center;
        padding: 0 2rem;

        & > h1 {
            font-size: 2.625rem;
        }

        & > p {
            font-size: 1.125rem;
        }
    }
}

.prop-page-slider {
    width: 100%;
}

// swiper
.prop-swiper-slide {
    height: unset;
}

.swiper-slide * {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prop-swiper {
    width: 100%;
    max-height: 500px;

    border-radius: 5px;
}

.prop-swiper-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prop-swiper-pagination {
    padding-right: 16px;
    padding-bottom: 8px;
}

.prop-swiper-pagination-bullets {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap,4px);
    width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,8px));
    height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,8px));
    display: inline-block;
    border-radius: 50%;
    border: 1.5px solid var(--ies-white);
    background: transparent;
}

.prop-swiper-pagination-bullet-active {
    background: var(--ies-white);
}

.custom-swiper-button-next {
    position: absolute;
    top: 50%;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 3vw;
    padding: 0.5rem;
    border-radius: 0.5rem;

    width: 1.875rem;
    height: 1.875rem;

    background: var(--ies-primary);
    z-index: 10;
    cursor: pointer;

    & > img {
        width: 100%;
        height: auto;
    }
}

.custom-swiper-button-prev {
    position: absolute;
    top: 50%;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 3vw;
    padding: 0.5rem;
    border-radius: 0.5rem;

    width: 1.875rem;
    height: 1.875rem;

    background: var(--ies-primary);
    z-index: 10;
    cursor: pointer;

    & > img {
        width: 100%;
        height: auto;
    }
}

.swiper-button-disabled {
    opacity: 0.5;
}

@media (min-width: map-get($screen-sizes, "md")) {

    .prop-swiper {
        width: 100%;
        height: 100%;
        max-height: unset;

        border-radius: 0px;
    }

    .custom-swiper-button-next {
        margin-right: 1.5vw;
        padding: 0.75rem;
        width: 3rem;
        height: 3rem;
    }

    .custom-swiper-button-prev {
        margin-left: 1.5vw;
        padding: 0.75rem;
        width: 3rem;
        height: 3rem;
    }
}