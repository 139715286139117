@import "~bootstrap/scss/mixins/banner";
@include bsBanner("");

// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
@import "./variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "./root";

:root {
  --nav-bar-height: 96px;  
}


@import "~bootstrap/scss/reboot";

body {
  font-size: (var(--#{$prefix}body-font-size-sm));
}

@media (min-width: map-get($screen-sizes, "md")) {
  body {
    font-size: (var(--#{$prefix}body-font-size-base));
  }  
}

h2 {
  font-weight: 500;
}

figure {
    margin: 0 0;
}

//@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";

@import "./Button/Button.scss";
@import "./gallery.scss";
@import "./imageCard/img-card";

// utils
@import "~bootstrap/scss/utilities/api";

.flex-spacer {
    flex-grow: 1;
}

.bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.bg-img-b {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  z-index: -1;
}

.a-href-link {
  &:hover {
    opacity: 0.9;
  }
}