@import "/src/core-ui/screens";


.nav-bar {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: var(--nav-bar-height);

    padding: 24px;
    z-index: 999;
}

.nav-bar-logo {
    & img {
        width: 96px;
    }
}

@media (min-width: map-get($screen-sizes, "md")) {
    .nav-bar {
        padding-left: 48px;
        padding-right: 48px;
    }

    .nav-bar-logo {
        & img {
            width: 104px;
        }
    }
}

@media (min-width: map-get($screen-sizes, "xl")) {
    .nav-bar {
        padding-left: 96px;
        padding-right: 96px;
    }

    .nav-bar-logo {
        & img {
            width: 146px;
        }
    }
}

.nav-bar-links {
    --gap: 10px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    flex-grow: 1;

    margin-left: 2rem;

    font-size: 1.2rem;
    font-weight: 400;
    gap: var(--gap);
}

.nav-link {
    text-decoration: none;

    padding: 0.25rem 0.75rem;

    &--active {
        border-bottom: 1.7px solid;
    }

    &:hover {
        color: var(--ies-secondary)
    }
}



.moblie-nav-toggle {
    display: none;
}

@media (max-width: 860px) {
    .nav-bar-links {
        --gap: 24px;
        position: fixed;
        margin: 0 auto;
        inset: 0 0 0 calc(100% - 228px);
        flex-direction: column;
        justify-content: initial;
        align-items: flex-start;
        padding-top: 96px;
        padding-left: 16px;
        padding-right: 16px;
        z-index: 999;

        background-color: var(--ies-white);
        opacity: 0.9;

        transform: translateX(100%);
        transition: transform 350ms ease-out;  
        
        & > button {
            align-self: center;
        }

        & > .flex-spacer {
            display: none;
        }
    }

    .nav-bar-links[data-visible="true"] {
        transform: translateX(0%);
    }

    .moblie-nav-toggle {
        display: block;
        position: absolute;
        right: 24px;
        top: 32px;
        width: 32px;
        aspect-ratio: 1;
        z-index: 9999;

        background: url(../../../public/images/hamburger.svg);
        background-repeat: no-repeat;
        background-position: center;

        border: 0;
    }

    .moblie-nav-toggle[aria-expanded="true"] {
        background-image: url(../../../public/images/close.svg);
        position: fixed;
    }
    
    .moblie-nav-toggle:focus-visible {
        outline: 5px solid white;
        outline-offset: 5px;
    }
}

.sr-only {
    position: absolute; 
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px; 
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
}