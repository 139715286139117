.gallery {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    gap: 5px;

    & .gallery-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}