@import "/src/core-ui/screens";

.home-prop-section-head {

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & * {
        color: var(--ies-primary)
    }
}

.home-prop-section-link {
    display: flex;
    align-items: center;

    transition: gap ease 350ms;

    &:hover {
        gap: 5px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
}

.home-prop-section-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 50vw 32vw 50vw;
    gap: 10px;

    margin-top: 20px;

    & .grid-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.home-prop-section-grid-item--1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    grid-column: 1 / 3;
    grid-row: 1;
}

.home-prop-section-grid-item--2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    grid-column: 2;
    grid-row: 2;
}

.home-prop-section-grid-item--3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    grid-column: 1;
    grid-row: 2;
}

.home-prop-section-grid-item--4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    grid-column: 1 / 3;
    grid-row: 3;
}

@media (min-width: map-get($screen-sizes, "md")) {

    .home-prop-section-grid {
        grid-template-columns: repeat(24, 1fr);
        grid-template-rows: repeat(2, 31.25vw);
    
        margin-top: 24px;
    }

    .home-prop-section-grid-item--1 {
        grid-column: 1 / 14;
        grid-row: auto;
    }
    
    .home-prop-section-grid-item--2 {
        grid-column: 14 / 25;
        grid-row: auto;
    }
    
    .home-prop-section-grid-item--3 {
        grid-column: 1 / 12;
        grid-row: auto;
    }
    
    .home-prop-section-grid-item--4 {
        grid-column: 12 / 25;
        grid-row: auto;
    }
}


@media (min-width: map-get($screen-sizes, "xl")) {

    .home-prop-section-grid {
        grid-template-columns: repeat(24, 1fr);
        grid-template-rows: repeat(2, max(15vw, 280px));
    
        margin-top: 80px;
    }

    .home-prop-section-grid-item--1 {
        grid-column: 1 / 14;
    }
    
    .home-prop-section-grid-item--2 {
        grid-column: 14 / 25;
    }
    
    .home-prop-section-grid-item--3 {
        grid-column: 1 / 12;
    }
    
    .home-prop-section-grid-item--4 {
        grid-column: 12 / 25;
    }
}