.arrow-link {
  display: flex;
  gap: 2px;
  align-items: center;

  & * {
    transition: all ease-in 250ms;
  }

  & > p {
    margin-bottom: 0;
  }

  &:hover {
      & > p {
        font-weight: 600;
      }

      & > svg {
        font-weight: bold;
        transform: translateX(3px);

        & > path {
          stroke-width: 2.1;
        }
      }
  }
}

.back-arrow-link {
  display: flex;
  gap: 2px;
  align-items: center;

  & * {
    transition: all ease-in 250ms;
  }

  & > p {
    margin-bottom: 0;
  }

  &:hover {
    & > p {
      font-weight: 600;
    }

    & > svg {
      font-weight: bold;
      transform: translateX(-3px);

      & > path {
        stroke-width: 2.1;
      }
    }
  }
}
